import React from 'react';
import QRCode from 'react-qr-code';
import { action, computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faArrowRotateBackward, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { AirportDto, FlightDto, Order, TransactionStatus } from '@app/models/WebApiModels';
import { BasketDTO } from '@app/models/AppModels';
import ApiService from '@app/services/ApiService';
import Body from '@app/pages/HomePage/components/Body/Body';
import Header from '@app/components/Header/Header';
import { ApiUrls } from '@app/AppConstants';
import { loaderStore } from '@app/stores/LoaderStore';
import DateTimeService from '@app/services/DateTimeService';
import { Button, ButtonLink } from '@app/components';
import { LocalStorageClear } from '@app/helpers/Helpers';
import { modalService } from '@app/components/Modal/Modal';
import { AvailableFlightsDialog, AvailableFlightsDialogProps } from '@app/components/Dialogs/AvailableFlights/AvailableFlightsDialog';
import PayerInformationDialog, { PayerInformationDialogProps } from '@app/components/Dialogs/PayerInformation/PayerInformationDialog';
import { applyDecorators } from '@app/helpers/Decorator';

import config from '../../config.json';
import cls from './_home.module.scss';
import { Icon } from '@app/components/Icon';

type HomePageProps = {
    airport: AirportDto;
};

@observer
export default class HomePage extends React.Component<HomePageProps, {}> {
    @observable private _flightList: FlightDto[] = [];
    @observable private _flight: FlightDto | null = null;
    @observable private _basket: BasketDTO[] = [];
    @observable private _payment: Order | null = null;
    @observable private _paymentStatusInterval: number | null = null;
    @observable private _connexDestination?: string;

    constructor(props: HomePageProps) {
        super(props);
        applyDecorators(this);
    }

    render() {
        if (!this._flightList.length) {
            return this._renderZeroFlightsView();
        }

        return (
            <div className={cls.root}>
                {this._renderHeader()}
                <Body airport={this.props.airport} basket={this._basket} onBasketChange={(b) => (this._basket = b)} onConnexDestinationChange={(c) => (this._connexDestination = c)} flight={this._flight!} />
                <div className={cls.footer}>
                    {!this._payment && this._renderResetNode()}
                    <Button className={cls.action} label="go next" disabled={!this._basket.length} onClick={this._openPayerInformationDialog} />
                </div>
                {this._payment && this._renderPayment()}
            </div>
        );
    }

    private _renderHeader() {
        const count = this._basket.map((e) => e.count).reduce((partialSum, a) => partialSum + a, 0) || 0;
        const currency = this._basket[0]?.service.currency;
        const canSwitchFlight = this._flightList.length > 1;

        let titleNode = <div className={cls.name}>{this.title}</div>;
        if (canSwitchFlight) {
            titleNode = (
                <div className={cls.dropdown} onClick={this._openFlightListDialog}>
                    {this.title} <Icon className={cls.icon} name="angle-down" />
                </div>
            );
        }
        const totalPrice = this._basket.map((b) => b.service.price * b.count).reduce((partialSum, a) => partialSum + a, 0);

        return (
            <div className={cls.header}>
                {titleNode}
                <div className={cls.basket} onClick={count ? this._openPayerInformationDialog : undefined}>
                    {!!count && (
                        <>
                            <div className={cls.count}>{count}</div>
                            <div className={cls.total}>
                                {currency} {totalPrice.toFixed(2)}
                            </div>
                        </>
                    )}
                    <Icon className={cls.icon} name="basket-shopping" />
                </div>
            </div>
        );
    }

    private _renderZeroFlightsView() {
        return (
            <>
                <div className={cls.emptyText}>No Flights available.</div>
                <div className={cls.emptyReset}>{this._renderResetNode()}</div>
                <Button className={cls.emptyAction} label="Refresh" onClick={() => void this._loadFlightList()} />
            </>
        );
    }

    private _renderPayment() {
        return (
            <div className={cls.payment}>
                <div className={cls.qrNode}>
                    {this._payment?.status === TransactionStatus.Paid && <FontAwesomeIcon className={cls.payedIcon} icon={faCheckCircle} />}
                    <QRCode className={cls.qr} value={this.datatransUrl} size={512} viewBox="0 0 512 512" />
                </div>
                <ButtonLink className={cls.payAction} hidden={this._payment?.status !== TransactionStatus.Ordered} isInline={true} label="Pay On Device" target="_blank" href={this.datatransUrl} />
                <div className={cls.statusNode}>
                    {this._payment!.status === TransactionStatus.Ordered && 'Payment in process'}
                    {this._payment!.status === TransactionStatus.Paid && 'Payment completed'}
                    {this._payment!.status === TransactionStatus.Failed && 'Payment failed!'}
                    {this._payment!.status === TransactionStatus.Canceled && 'Payment canceled!'}
                    <span hidden={this._payment?.status !== TransactionStatus.Ordered} className={cls.dotPulse}></span>
                </div>
                <Button className={cls.emptyAction} label="Close" onClick={this._onFinishClick} />
            </div>
        );
    }

    private _renderResetNode() {
        return (
            <div className={cls.reset} onClick={this._onResetClick}>
                <FontAwesomeIcon icon={faArrowRotateBackward} />
            </div>
        );
    }

    componentDidMount() {
        void this._loadFlightList();
    }

    @action.bound
    private async _openPayerInformationDialog() {
        const payment = await modalService.show<PayerInformationDialogProps, Order>(PayerInformationDialog, {
            basket: this._basket,
            flight: this._flight!,
            connexDestination: this._connexDestination
        });

        this._payment = payment.result;
        this._pullForPaymentStatus();
    }

    @action.bound
    private async _openFlightListDialog() {
        const selectedFlight = await modalService.show<AvailableFlightsDialogProps, FlightDto>(AvailableFlightsDialog, {
            flightList: this._flightList,
            selectedFlight: this._flight ?? undefined,
            originAirport: this.props.airport.code ?? ''
        });

        runInAction(() => {
            this._flight = selectedFlight.result ?? this._flight;
        });
    }

    @action.bound
    private async _loadFlightList() {
        const { data } = await ApiService.getTypedData<FlightDto[]>(`${ApiUrls.AirportUrl}/flights/${this.props.airport.code}`, null, { completion: loaderStore.appLoader });
        this._flightList = data;

        if (this._flightList.length > 0) {
            this._flight = this._flightList[0];

            if (this._flightList.length > 1) {
                this._openFlightListDialog();
            }
        }
    }

    @action.bound
    private _onResetClick() {
        void modalService.showConfirmation("You're gonna to reset app", {
            onConfirm: () => {
                this._resetApp();
            }
        });
    }

    @action.bound
    private _onFinishClick() {
        this._payment = null;
        this._basket = [];
    }

    private _pullForPaymentStatus() {
        let lastIntervalCompleted = true;
        this._paymentStatusInterval = window.setInterval(async () => {
            if (!lastIntervalCompleted) return;
            if (!this._payment) return;

            lastIntervalCompleted = false;

            const { data } = await ApiService.getTypedData<Order>(`${ApiUrls.PaymentUrl}/${this._payment.id}`, null);
            this._payment = data;

            
            if (data.status !== TransactionStatus.Ordered) {
                clearInterval(this._paymentStatusInterval!);
            }
            
            lastIntervalCompleted = true;
        }, 1000 * 3);
    }

    private _resetApp() {
        LocalStorageClear();
        window.location.reload();
    }

    @computed
    private get title() {
        const title = this._flight ? `${this._flight.airlineRcd}${this._flight.flightNumber} (${this._flight.departureAirport} - ${this._flight.arrivalAirport}) - ${DateTimeService.format(this._flight.departureDateTimeUtc!, 'DDMMMYY')} - ETD ${DateTimeService.format(this._flight.departureDateTimeUtc!, 'HH:mm')}` : '';
        return title;
    }

    @computed
    private get datatransUrl() {
        const url = config.api.dataTrans + this._payment?.datatransTransactionId;
        return url;
    }
}
